<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="First Name" rules="required">
          <v-text-field v-model="users.first_name" :error-messages="errors" label="First Name" required outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Last Name" rules="required">
          <v-text-field v-model="users.last_name" :error-messages="errors" label="Last Name" required outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Email" rules="required">
          <v-text-field v-model="users.email" :error-messages="errors" label="Email" required outlined> </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Email Verified At" rules="">
          <v-text-field v-model="users.email_verified_at" :error-messages="errors" label="Email Verified At" outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Phone" rules="">
          <v-text-field v-model="users.phone" :error-messages="errors" label="Phone" outlined> </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Phone Verified At" rules="">
          <v-text-field v-model="users.phone_verified_at" :error-messages="errors" label="Phone Verified At" outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Password" rules="required">
          <v-text-field v-model="users.password" :error-messages="errors" label="Password" required outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Two Step Verification" rules="required">
          <v-text-field
            v-model="users.two_step_verification"
            :error-messages="errors"
            label="Two Step Verification"
            required
            outlined
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="First Login" rules="required">
          <v-text-field v-model="users.first_login" :error-messages="errors" label="First Login" required outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Remember Token" rules="">
          <v-text-field v-model="users.remember_token" :error-messages="errors" label="Remember Token" outlined>
          </v-text-field>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Update
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "UserRead",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { users: {} };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const { data } = await Request.get(`${this.$IYA.URI.USERS_READ}`, {
        id: this.$route.params.id,
      });
      this.users = data;
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.USERS_EDIT}`, this.users).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done!`, `Action accomplished`, `success`);
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
